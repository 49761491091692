import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder'
import { EditBox } from 'modules/WebBuilder/shared/EditBox'
import React, { useState } from 'react'
import { ReactComponent as SettingIcon } from 'assets/images/webBuilder/setting.svg'
import SocialMediaModal from './SocialMediaModal'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import UploadFileComponent from 'modules/WebBuilder/shared/UploadFile'
import { ReactComponent as LogoPlaceholder } from 'assets/images/webBuilder/logo-placeholder.svg'
import Locale from 'translations'

const currentYear = new Date().getFullYear()

export default function FooterTempTwo({ focusContainer, handleFocus, socialMediaIcons }) {
	const { webBuilder } = Locale;
	const location = useLocation()
	const history = useHistory()
	const params = useParams()
	const preview = !location.pathname.includes('edit')

	const { footer: footerDetails, hero, ourServices, selectedLanguage } = useWebBuilderState()
	const header = hero?.header;
	const hasLogo = header?.layout?.hasLogo;
	const services = ourServices?.services?.filter(service => service?.visible);


	const updateHeroHeader = ({ name, value, type }) => {
		dispatch({
			type: 'updateHeroHeader',
			payload: {
				name,
				value,
				type
			}
		})
	}

	const dispatch = useWebBuilderDispatch()
	const type = 'footer'
	const [socialMediaModal, setSocialMediaModal] = useState(false)
	function toggleSocialMediaModal() {
		setSocialMediaModal(!socialMediaModal)
	}


	const containerBoxActions = [
		<span onClick={toggleSocialMediaModal} className='pointer'>
			<SettingIcon />
		</span>
	]
	// function updateFooterContent(keyName, value, index) {
	// 	dispatch({
	// 		type: 'updateFooter',
	// 		payload: {
	// 			type: keyName,
	// 			value,
	// 			name: index
	// 		}
	// 	})
	// }

	const scrollToSection = (id) => {
		if (id) {
			const element = document.getElementById(id)
			element?.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const navFooter = (
		<>
			<div className='footer-col'>
				<h4 className='footerLinks-header'>{webBuilder.home}</h4>
				<ul>
					<li
						onClick={() => {
							let url = params.name
								? `/public-page-v2/${params.name}`
								: '/web-builder-v2/preview'
							if (params.id) {
								history.push({ pathname: `${url}`, state: 'about-us-section' })
							} else {
								scrollToSection('about-us-section')
							}
						}}
						className={'text-secondary-color'}
					>
						{webBuilder.aboutus}
					</li>

					<li
						onClick={() => {
							let url = params.name
								? `/public-page-v2/${params.name}`
								: '/web-builder-v2/preview'
							if (params.id) {
								history.push({
									pathname: `${url}`,
									state: 'our-service'
								})
							} else {
								scrollToSection('our-service')
							}
						}}
						className={'text-secondary-color'}
					>
						{webBuilder.ourServices}
					</li>
				</ul>
			</div>

			<div className='footer-col d-none-mob'>
				<h4 className='footerLinks-header'>{webBuilder.getHelp}</h4>
				<ul>
					<li
						onClick={() => {
							let url = params.name
								? `/public-page-v2/${params.name}`
								: '/web-builder-v2/preview'
							if (params.id) {
								history.push({
									pathname: `${url}`,
									state: 'faq-section'
								})
							} else {
								scrollToSection('faq-section')
							}
						}}
						className={'text-secondary-color'}
					>
						{webBuilder.FAQ}
					</li>

					<li
						onClick={() => {
							let url = params.name
								? `/public-page-v2/${params.name}`
								: '/web-builder-v2/preview'
							if (params.id) {
								history.push({
									pathname: `${url}`,
									state: 'contact-us-section'
								})
							} else {
								scrollToSection('contact-us-section')
							}
						}}
						className={'text-secondary-color'}
					>
						{webBuilder.contactUs}
					</li>
				</ul>
			</div>

			<div className='footer-col'>
				<h4 className='footerLinks-header'>{webBuilder.ourServices}</h4>
				<ul>
					{services && services.length > 0 && services?.map(service=> {
						return (
							<li
								onClick={() => {
									let url = params.name
										? `/public-page-v2/${params.name}`
										: '/web-builder-v2/preview'
									if (params.id) {
										history.push({
											pathname: `${url}`,
											state: service?.id
										})
									} else {
										scrollToSection(service?.id)
									}
								}}
								className={'text-secondary-color'}
							>
								{service?.header[selectedLanguage]}
							</li>
						)
					})}
				</ul>
			</div>
		</>
	)
	const logoImage = header?.logo[selectedLanguage] ? (
		<img className='footer-photo' src={header?.logo[selectedLanguage]} alt='Logo' />
	) : null
	const logo = preview ? (
		logoImage
	) : (
		<UploadFileComponent
			icon={LogoPlaceholder}
			id='hero-logo'
			label=''
			// label="Change image"
			onUpload={(value) => updateHeroHeader({ name: 'logo', value })}
			image={logoImage}
		/>
	)

	return (
		<div className="w-100 px-md-5">
			<div className='footer-temp-two'>
				<div className='footerTempTwo-logo'>
					{hasLogo && header?.layout?.hasLogo ? logo : null}
					<div
						className='footer-socialIcons'
						onClick={() => handleFocus(type)}
					>
						<p className='footer-text'>{webBuilder.followUsOn}</p>
						<EditBox
							editMode={focusContainer === type}
							defaultIcon={containerBoxActions}
						>
							<div className='d-flex align-items-center gap-10 py-2'>
								{footerDetails?.links?.map((link) => {
									if (link?.visible) {
										return (
											<div key={link?.id}>
												<a href={link?.url} target='_blank' rel='noreferrer'>
													{socialMediaIcons[link?.id]}
												</a>
											</div>
										)
									} else {
										return null
									}
								})}
							</div>
						</EditBox>
					</div>
				</div>

				<div className='footerTempTwo-links'>{navFooter}</div>
			</div>
			<div className='footer-copyrights align-items-center'>
				<p>
					{webBuilder.allRightsReserved} {" - "}
					<a href="https://safasoft.com/" target="_blank" rel="noreferrer" className="text-white">{webBuilder.safasoft}</a>
					{" "} © {currentYear}
				</p>
			</div>

			{socialMediaModal && footerDetails?.links ? (
				<SocialMediaModal
					isOpen={socialMediaModal}
					toggle={toggleSocialMediaModal}
					header={'Social links'}
					socialMediaLinks={footerDetails?.links}
				/>
			) : null}
		</div>
	)
}
