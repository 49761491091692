import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
// import UploadFileComponent from "modules/WebBuilder-V2/shared/UploadFile";
import React, { useEffect, useRef, useState } from "react";

// import { ReactComponent as LogoPlaceholder } from "assets/images/webBuilder/logo-placeholder.svg";
import { useHistory, useLocation, useParams } from "react-router-dom";
import HeaderButtonModal from "../models/HeaderButton";
import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import { ReactComponent as PhoneIcon } from "assets/images/webBuilder/call.svg";
import { ReactComponent as MenuIcon } from "assets/images/webBuilder/menu.svg";
import Locale from 'translations';
import ChangeLogosModal from '../models/ChangeLogos';
import WebBuilderNav from './MainNav';

const URI = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";



export default function HeroHeader({ editHeader }) {
	const { webBuilder } = Locale;
	const { hero, mainMenu, style, selectedLanguage } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const [openButtonModal, setOpenButtonModal] = useState(false);
	const [changeLogoModal, setChangeLogoModal] = useState(false);
	const navRef = useRef(null);

	const location = useLocation();
	const preview = !location.pathname.includes("edit");


	const header = hero?.header;
	const layoutType = header?.layout?.layoutType;
	const hasButton = header?.layout?.hasButton;
	// const hasLogo = header?.layout?.hasLogo;
	const headerBg = header?.layout?.background === "white";
	const call_us = header?.layout?.callUs;
	const visibleMenuItems = mainMenu?.filter(item => item?.visible)

	const scrollToSection = (id) => {
		if (id) {
			const element = document.getElementById(id);
			element?.scrollIntoView({ behavior: "smooth" });
		}
	};

	const updateHeroHeader = ({ name, value, type }) => {
		dispatch({
			type: "updateHeroHeader",
			payload: {
				name,
				value,
				type,
			},
		});
	};
	const history = useHistory();
	const params = useParams();



	// scroll to location from view package page
	useEffect(() => {
		if (location.state) {
			setTimeout(() => {
				scrollToSection(location.state);
			}, 500);
		}
	}, []);


	const logoImage = header?.logo ? (
		header?.logo[[selectedLanguage]]?.includes('https://') ? (
			<img src={`${header?.logo[selectedLanguage]}`} alt="Logo" />
		) : (
			<img src={`${URI}/${header?.logo[selectedLanguage]}`} alt="Logo" />
		)
	) : null;

	const toggleButtonModal = (e) => {
		e?.stopPropagation();
		setOpenButtonModal((prev) => !prev);
	};
	const toggleLogoChangeModal = (e) => {
		e?.stopPropagation();
		setChangeLogoModal((prev) => !prev);
	};

	const clickContactButton = () => {
		if (preview) {
			scrollToSection(header?.contactUs?.id);
			return;
		}
		toggleButtonModal();
	};	

	function handleOpenMobileNav() {
		if (navRef.current.classList.contains("nav-opened")) {
			navRef.current.classList.remove("nav-opened")
		} else {
			navRef.current.classList.add("nav-opened")
		}
	}

	const contactButton = hasButton && (
		<div className="d-flex-language-contact d-flex align-items-center gap-10">
			<button
				className="contact-button mr-2"
				onClick={() => {
					// check if we inside view package page when click scroll back to preview page the scroll using useeffect
					let url = params.name
						? `/public-page-v2/${params.name}`
						: "/web-builder-v2/preview";
					if (params.id) {
						history.push({ pathname: `${url}`, state: header?.contactUs?.id });
					} else {
						clickContactButton()
					}
				}}
			>
				{header?.contactUs?.name?.[selectedLanguage] || header?.contactUs?.name}
			</button>
			{/* {languageSwitcherButton} */}

			{/* {call_us && (
				<div className="d-flex align-items-center ml-2 call-us">
					<PhoneIcon color="#1e85ff" />
					<div className="webBuilder-contact d-flex flex-column ml-1">
						<p>{webBuilder.callUs}</p>
						<EditableParagraph
							className="body text-primary-web-builder"
							contentEditable={true}
							onInput={(value) => {
								updateHeroHeader({ name: "phoneNumber", value });
							}}
							text={header?.phoneNumber}
							preventTranslate={true}
						/>
					</div>
				</div>
			)} */}

			<div className="d-flex align-items-center ml-2 call-us">
					<PhoneIcon color="#1e85ff" />
					<div className="webBuilder-contact d-flex flex-column ml-1">
						<p>{webBuilder.callUs}</p>
						<EditableParagraph
							className="body text-primary-web-builder"
							contentEditable={true}
							onInput={(value) => {
								updateHeroHeader({ name: "phoneNumber", value });
							}}
							text={header?.phoneNumber}
							preventTranslate={true}
						/>
					</div>
				</div>
		</div>
	);

	const onSaveButton = ({ name, id }) => {
		updateHeroHeader({ name: "name", value: name, type: "contactUs" });
		updateHeroHeader({ name: "id", value: id, type: "contactUs" });
	};

	const saveCompanyLogos = (logos) => {
		updateHeroHeader({ name: "logo", value: logos })
	};

	return (
		<>
			<div className="web-builder-hero-header" onClick={editHeader}>
				{layoutType === 1 || layoutType === 2 ? (
					<header className={`${layoutType === 2 ? "flex-row-reverse " : ""}`}>
						{/* logo */}
						<div className="logo"
							onClick={() => {
								// check if we inside preview 
								let url = params.name ? `/public-page-v2/${params.name}` : "/web-builder-v2/preview";
								if (preview) {
									history.push(url);
								} else {
									toggleLogoChangeModal();
								}
							}}
						>
							{logoImage}
						</div>

						{(style?.theme === "theme-1" || style?.theme === "theme-3") &&
							<>
								<WebBuilderNav
									navRef={navRef}
									handleOpenMobileNav={handleOpenMobileNav}
									preview={preview}
									headerBg={headerBg}
									visibleMenuItems={visibleMenuItems}
								/>
								{contactButton}
								<button className="nav-mobile-icon" onClick={handleOpenMobileNav}>
									<MenuIcon />
								</button>
							</>
						}
						{style?.theme === "theme-2" &&
							<div className="nav-container">
								<WebBuilderNav
									navRef={navRef}
									handleOpenMobileNav={handleOpenMobileNav}
									preview={preview}
									headerBg={headerBg}
									visibleMenuItems={visibleMenuItems}
								/>
								{contactButton}
								<button className="nav-mobile-icon" onClick={handleOpenMobileNav}>
									<MenuIcon />
								</button>
							</div>
						}

					</header>
				) : (
					<header className={`${layoutType === 4 ? "flex-row-reverse " : ""}`}>
						{contactButton}

						<div className="logo"
							onClick={() => {
								let url = params.name ? `/public-page-v2/${params.name}` : "/web-builder-v2/preview";
								if (preview) {
									history.push(url);
								} else {
									toggleLogoChangeModal();
								}
							}}
						>
							{logoImage}
						</div>

						<WebBuilderNav
							navRef={navRef}
							handleOpenMobileNav={handleOpenMobileNav}
							preview={preview}
							headerBg={headerBg}
							visibleMenuItems={visibleMenuItems}
						/>
					</header>
				)}
			</div>

			<HeaderButtonModal
				save={onSaveButton}
				isOpen={openButtonModal}
				toggle={toggleButtonModal}
			/>

			{changeLogoModal &&
				<ChangeLogosModal
					isOpen={changeLogoModal}
					toggle={toggleLogoChangeModal}
					save={saveCompanyLogos}
				/>
			}
			{/* logo modal */}
		</>
	);
}
