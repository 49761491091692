import React, { useState } from 'react'
import { ReactComponent as SaveIcon } from "assets/images/webBuilder/save.svg";
import { ReactComponent as EyeIcon } from "assets/images/webBuilder/eye.svg";
import { setPageBuilder, updateCompanyWhatsAppNumber } from 'services/webBuilder';
import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { useSBSState } from 'context/global';
import { GlobalIcon } from 'modules/TripPlanner/ViewEdit/Icons';
import { EditIcon } from 'modules/UmrahOperations/shared/Icons';
import UpdateWhatsAppModal from 'modules/WebBuilder-V2/shared/UpdateWhatsAppModal';
import PublishModal from 'modules/WebBuilder-V2/shared/PublishModal';
import Locale from 'translations';



function Header({ version }) {
	const { commons } = Locale;
	const pageData = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();

	const [mainSecIsOpen, setMainSecIsOpen] = useState(false);
	const [NewShowMassage, setNewShowMassage] = useState(false);
	const mainToggle = () => setMainSecIsOpen(!mainSecIsOpen);
	const { companyInfo } = useSBSState();

	const [mainToggleUpdateWhats, setmainToggleUpdateWhats] = useState(false);

	const mainToggleWhats = () => setmainToggleUpdateWhats(!mainToggleUpdateWhats);


	const savePage = async () => {
		const res = await setPageBuilder({ page_builder_config: pageData });
		if (res.status === 200) {
			setNewShowMassage(!NewShowMassage)
			setTimeout(() => {
				setNewShowMassage(false)
			}, 2000);
		}
	}

	function copyText(text) {
		navigator.clipboard.writeText(text).then(() => {
			store.addNotification({
				message: "URL Copied",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		});
	};


	function selectLanguage(languageCode) {
		dispatch({
			type: "selectLanguage",
			payload: languageCode
		})
		Locale.setLanguage(languageCode);
	}


	async function updateWhatsAppNumber(phone_code, phone_number, successCallback) {
		let data = {
			public_page_phone_code: phone_code?.phone_code,
			public_page_phone: phone_number,
		}
		const res = await updateCompanyWhatsAppNumber(data);
		if (res?.status === 200) {
			store.addNotification({
				message: "Whatsapp number updated successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			successCallback && successCallback()
		}
	}



	return (
		<>

			<div className="web-builder-header justify-content-between">
				<div className="d-flex align-items-center gap-10">
					{/* <span className="url-icon">
						{" "}
						<ComputerIcon />
					</span>
					{companyInfo?.public_page_slug ? (
						<>
							<span>URL:</span>
							<span
								className="pointer"
								style={{ color: "#007bff" }}
								onClick={() =>
									copyText(
										`sbs.dev.safa.asia/public-page-v2/${companyInfo?.public_page_slug}`
									)
								}
							>
								sbs.dev.safa.asia/public-page-v2/{companyInfo?.public_page_slug}
							</span>
						</>
					) : null} */}
				</div>
		 

				<div className="d-flex gap-10">
					{NewShowMassage ? <span className='d-flex align-items-center  animate_New_fadeIn'>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7.75 12.0019L10.58 14.8319L16.25 9.17188"
								stroke="#707170"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						Saved changes
					</span> : null}
					<button onClick={savePage} className="bg-white border-0">
						{/* <span className="mx-1">Save Changes</span> */}
						<SaveIcon />
					</button>


					{companyInfo?.public_page_slug ?
						<button className="whatsApp-btn btn"
							onClick={() => {
								mainToggleWhats();
							}}	>
							<span>
								<EditIcon />
							</span>
							What’sApp Number
						</button>
						: null
					}


					{mainToggleUpdateWhats && (
						<UpdateWhatsAppModal
							isOpen={mainToggleUpdateWhats}
							toggle={mainToggleWhats}
							updateWhatsAppNumber={updateWhatsAppNumber}
							phone_code={pageData?.public_page_phone_code}
							phone_number={pageData?.public_page_phone}
						/>
					)}



					<Link to={`/web-builder-${version}/preview`} className="header-preview">
						<EyeIcon className="header-eye-icon" /> Preview
					</Link>
					<div className="d-flex position-relative">
						<button
							className="header-publish"
							onClick={() => {
								mainToggle();
							}}
						>
							Publish
						</button>
					</div>

					{mainSecIsOpen && (
						<PublishModal
							isOpen={mainSecIsOpen}
							toggle={mainToggle}
							updateWhatsAppNumber={updateWhatsAppNumber}
							phone_code={pageData?.public_page_phone_code}
							phone_number={pageData?.public_page_phone}
							slug={pageData?.slug}
						/>
					)}
				</div>
			</div>


			{/* lagauges */}
			<div className="web-builder-header-languages justify-content-center">
				<span className="d-flex align-items-center gap-10 pb-3">
					<GlobalIcon color="#707170" />
					{commons.languages}:
				</span>
				<ul className="list">
					{pageData?.languages?.map(language => {
						return (
							<li className={pageData?.selectedLanguage?.toLowerCase() === language?.code?.toLowerCase() ? "active" : "pointer"}
								onClick={() => selectLanguage(language?.code)}
							>
								{language.name}
								{" "}
								<span className="text-uppercase">({language?.code})</span>
							</li>
						)
					})}
				</ul>
			</div>

		</>
	);
}

export default Header