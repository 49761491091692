import React from "react";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-2.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-black.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-black.svg";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import moment, { now } from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Locale from 'translations';

function PackageCard(props) {
	const {commons, webBuilder} = Locale;
	const {
		onClick,
		image,
		name,
		departure_date_at,
		return_date_at,
		stars,
		product_uuid,
		hotelName,
		price,
		locationName,
		openEditModal,
		initialName
	} = props;
	const location = useLocation();
	const history = useHistory();
	const preview = !location.pathname.includes("edit");
	const params = useParams();
	const trimHotelName = hotelName ? hotelName?.length > 16 ? `${hotelName?.substr(0, 16)}...` : hotelName : `${webBuilder.hotelName}`


	return (
		<div className={`package-card-container `}
			onClick={() => {
				if (product_uuid) {
					if (preview) {
						if (params.name) {
							history.push(`/web-v2/${params.name}/package/${product_uuid}`);
						} else {
							history.push(`/web-builder-v2/preview/package/${product_uuid}`);
						}
					}
				}
			}}
			key={product_uuid}
		>
			<div onClick={onClick} className={`package-card-details`}>

				<div className={`package-img ${!image ? "empty-service" : ""}`}>
					{!preview && !image ?
						<button className="btn add-service-btn" onClick={() => openEditModal(props)}>
							<AddIcon />
						</button>
						:
						null
					}
					{image && <img src={image} alt="our package" />}
					<span className="card-ribbon">{webBuilder.topRate}</span>
					<div className="package-img-data">
						<p className="font-weight-bold">{name || initialName}</p>
						{stars > 0 &&
							<div className="starts">
								{[...Array(5)].map((i, index) =>
									index < stars ? <GoldenStarIcon /> : <StarIcon />
								)}
							</div>
						}
					</div>
				</div>
				<div className="package-info">
					<div className='location'>
						<LocationIcon />{" "}
						<span className='mr-2'>{locationName}</span>
					</div>
					<div className="date">
						<CalendarIcon />{" "}
						<span className=''>{`${moment(departure_date_at || now()).format(
							"DD MMM YYYY"
						)} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}</span>
					</div>
					{hotelName ? (
						<div className="hotel mt-1">
							<Hotelcon /> <span className='mr-2'>{trimHotelName}</span>
						</div>
					) : null}
					<div className='hr' />
					<div className="price">
						<span className="amount d-inline-block">
							<span className='d-inline-block'>{ commons.from }</span>
							<span className="mx-1"></span>
							{price}
							<span className="mx-1"></span>
							<span className='d-inline-block currency'>{props?.currency}</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PackageCard;
