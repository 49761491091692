import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { ReactComponent as CloseIcon } from "assets/images/webBuilder/close-nav.svg";
import { useHistory, useParams } from "react-router-dom";
import Locale from 'translations';
import { useWebBuilderState } from 'context/webBuilder';


export default function WebBuilderNav({ navRef, handleOpenMobileNav, preview, headerBg, visibleMenuItems }) {
  const { webBuilder } = Locale;
  const params = useParams();
  const history = useHistory();
  const { selectedLanguage } = useWebBuilderState();


  
  return (
    <nav className="header-nav" ref={navRef}>
      <button className="close-nav" onClick={handleOpenMobileNav}>
        <CloseIcon />
      </button>
      <ul className="main-nav">
        <li
          onClick={() => {
            if (preview) {
              if (params.name) {
                history.push(`/public-page-v2/${params.name}`);
              } else {
                history.push(`/web-builder-v2/preview`);
              }
            }
          }}
          className={headerBg ? "text-secondary-color" : ""}
        >
          {webBuilder.home}
        </li>
        {visibleMenuItems?.map(item => {
          return (
            <li key={item?.id} className="position-relative"
              onClick={() => {
                if (!item?.subNavs || item?.subNavs?.length === 0) {
                  let url = params.name
                    ? `/web-v2/${params.name}/${item.url}`
                    : `/web-builder-v2/preview/${item?.url}`;
                  if (preview) {
                    history.push({ pathname: `${url}` });
                  }
                  handleOpenMobileNav()
                }
              }}
            >
              {item?.subNavs?.length > 0 ? null : <span>{item?.title?.[selectedLanguage]}</span>}
              {/********************************************** sub navs *************************************************************/}
              {item?.subNavs?.length > 0 &&
                <UncontrolledDropdown>
                  <DropdownToggle caret className={`service-dropdown`}>
                    {item?.title?.[selectedLanguage]}
                  </DropdownToggle>

                  <DropdownMenu right>
                    {item?.subNavs.map((subNav) =>
                      <DropdownItem key={subNav.id}
                        onClick={() => {
                          let url = params.name
                            ? `/web-v2/${params.name}/${subNav.url}`
                            : `/web-builder-v2/preview/${subNav?.url}`;
                          if (preview) {
                            history.push({ pathname: `${url}` });
                          }
                          handleOpenMobileNav()
                        }}
                      >
                        {subNav.title[selectedLanguage]}
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
            </li>
          )
        })}


      </ul>
    </nav>
  )
}